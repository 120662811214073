
































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { NavigationId } from "@/store/generalSettings/types";
import { ValidationProvider } from "vee-validate";

@Component({ components: { ValidationProvider } })
export default class TabSwitch extends Vue {
  @Prop({ required: true }) readonly tab!: NavigationId;
  @Prop({ required: true }) readonly label!: string;
  @Prop({ default: false }) readonly value!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({}) readonly icon?: string;
  @Prop({ default: "" }) rules!: Array<Record<any, any> | string>;
  @Ref("provider") provider!: InstanceType<typeof ValidationProvider>;

  get active(): boolean {
    return this.value;
  }
  set active(val: boolean) {
    this.$emit("input", val);
  }

  onClick(): void {
    this.provider.validate();
    this.active = !this.active;
  }
}
