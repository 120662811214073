




























































































import { Action, Getter } from "vuex-class";
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import {
  AddChatSettings,
  UserColorMode,
  ChatSettings,
} from "@/store/generalSettings/types";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import { ChatSettings as ChatSettingsInterface } from "@/store/generalSettings/types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import NumberInput from "./NumberInput.vue";
import { VForm } from "@/types";

@Component({
  components: { ValidationObserver, ValidationProvider, NumberInput },
})
export default class SettingsChat extends Vue {
  @Prop({ required: true }) readonly broadcast!: Broadcast | null;

  @Ref("observer") observer!: InstanceType<typeof ValidationObserver>;
  @Ref("form") form!: VForm;

  @Action("generalSettings/applyChatSettings")
  applyChatSettings!: (data: AddChatSettings) => Promise<ChatSettings>;
  @Action("generalSettings/getChatSettings")
  getChatSettings!: (broadcastId?: string) => Promise<ChatSettings>;

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];
  @Getter("generalSettings/chatSettings")
  readonly chatSettings!: ChatSettingsInterface | null;

  loading = false;
  disabled = true;
  sending = false;

  // settings: ChatSettings = this.chatSettings;
  formData = {
    enableSpamProtection: true,
    allowedCharactersCount: 0,
    maxSameMessageCount: 0,
    maxSameMessageResetTime: 0,
    maxMessageCountByTime: 0,
    maxMessageCountSeconds: 60,
    maxMessageCountByTimeSlow: 1,
    maxMessageCountSecondsSlow: 1,
    allowLink: false,
    messageHistoryAgeLimit: 0,
    messageHistoryCount: 0,
    userColorMode: UserColorMode.NONE,
    pollsInitialResultDisplay: "",
  };

  submit() {
    this.sending = true;
    this.applyChatSettings({
      ...this.formData,
      broadcastId: this.broadcast?.id || undefined,
      mode: "all",
    }).then(() => {
      this.sending = false;
      this.observer.reset();
    });
  }

  @Watch("broadcast", { immediate: true })
  onBroadcastSelect(broadcast: Broadcast | null) {
    this.loading = true;
    this.disabled = true;
    this.getChatSettings(broadcast?.id)
      .then((res) => {
        this.formData = {
          ...res,
          messageHistoryCount: res.messageHistoryCount ?? 0,
          messageHistoryAgeLimit: res.messageHistoryAgeLimit ?? 0,
        };
        return this.$nextTick();
      })
      .then(() => {
        this.observer.reset();

        this.loading = false;
        this.disabled = false;
      });
  }

  colorOptionLabel(colorMode: UserColorMode): string {
    switch (colorMode) {
      case UserColorMode.NONE:
        return this.$t("userColorMode.none").toString();
      case UserColorMode.RANDOM:
        return this.$t("userColorMode.random").toString();
      default:
        return colorMode || "";
    }
  }
}
