



























import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({ components: { ValidationProvider } })
export default class NumberInput extends Vue {
  @Prop({ required: true }) readonly label!: string;
  @Prop({ default: false }) readonly value!: number;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ required: false }) readonly help?: string;

  @Ref("provider") provider!: InstanceType<typeof ValidationProvider>;

  get number(): string {
    return this.value.toString();
  }
  set number(val: string) {
    if (typeof val === "string") this.$emit("input", parseInt(val, 10));
    else this.$emit("input", val);
  }
}
