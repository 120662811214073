import { UserInfo } from "@/spect8-core-vue/src/types";
import { MessageRouter, MessageRouterPayload } from "@/types";

export interface GeneralState {
  generalError: string;
  channelsError: string;
  chatSettings: ChatSettings | null;
  channels: Channel[];
  pinnedMessagesList: PinnedMessage[];
  shopCategories: CategoryResponse[];
  shopItems: ShopItem[];
  disclaimers: ShopDisclaimer[];
  navbarItems: Navigation;
}

export interface ChatSettings {
  mode: ChatMode;
  enableSpamProtection: boolean;
  allowedCharactersCount: number;
  maxSameMessageCount: number;
  maxSameMessageResetTime: number;
  maxMessageCountByTime: number;
  maxMessageCountSeconds: number;
  maxMessageCountByTimeSlow: number;
  maxMessageCountSecondsSlow: number;
  allowLink: boolean;
  messageHistoryAgeLimit: number | null;
  messageHistoryCount: number | null;
  userColorMode: UserColorMode;
  pollsInitialResultDisplay: string;
}

export type ChatMode = "all" | "subscribers" | "followers" | "slow";

export interface AddChatSettings extends ChatSettings {
  broadcastId?: string;
}

export interface ChatSettingsDTO extends ChatSettings {
  id: string;
  tenantId: string;
  broadcastId: string | null;
  timeCreated: string;
  lastEdited: string;
}
export type ChatSettingsResponse = ChatSettingsDTO;

export interface Channel {
  id: string;
  channelName: string;
  broadcast: {
    broadcastId: string;
    tenantId: string;
    broadcastName: string;
  };
}

export interface PinnedMessage {
  id: string;
  messageRouter: MessageRouter;
  pinnedMessage: string;
  dateCreated: number;
  validUntil: number;
  createdBy: UserInfo;
}

export interface AddPinnedMessageRequest {
  pinnedMessage: string;
  durationMs: number;
  messageRouterPayload: MessageRouterPayload;
}

export interface AnswerObject {
  key: UserInfo[];
}

export interface Answer {
  optionId?: string;
  text: string;
  votes: number;
  percent?: string;
  selected?: boolean;
}
export interface VotedPoll {
  pollId: string;
  userId: string;
  answered: boolean;
}

//vote poll
export interface PollStatistic {
  pollId: string;
  optionId: number;
}

export interface ShopCategory {
  categoryName: string;
}

export interface CategoryResponse {
  id: string;
  tenantId: string;
  categoryName: string;
}

export interface ShopItem {
  id?: string;
  categoryId: string;
  itemName: string;
  itemDetails: string;
  url: string;
  itemPrice: number;
  currency: string;
  redirectLink: string;
  image?: File | null;
}

export interface ShopItemResponse {
  id?: string;
  categoryId: Array<string>;
  itemName: string;
  itemDetails: string;
  url: string;
  itemPrice: number;
  currency: string;
  redirectLink: string;
  image?: File | null;
}

export interface ShopDisclaimer {
  id?: string;
  disclaimer: string;
  link: string;
}

export interface Navigation {
  id: string;
  tabs: NavigationItem[];
  // ???
  // tenantPayload: TenantPayload;
  broadcastId: string | null;
}

export interface NavigationItem {
  icon: string;
  name: NavigationId;
  rank: number;
}

export enum NavigationId {
  CHAT = "CHAT",
  MERCH = "MERCH",
  POLLS = "POLLS",
  QA = "QA",
  RESOURCES = "RESOURCES",
  //   TRIVIA_RAFFLE = "TRIVIA_RAFFLE",
  //   INFO = "INFO",
}

export interface NavigationDeleteRequest {
  name: NavigationId;
  broadcastId: string | null;
}

export interface NavigationRequest {
  name: Array<NavigationId>;
  broadcastId: string | null;
}

export interface NavigationDto {
  id: string;
  tabs: NavigationItem[];
  tenantId: string;
  broadcastId: string | null;
  timeCreated: string;
  lastEdited: string;
}

export enum UserColorMode {
  NONE = "NONE",
  RANDOM = "RANDOM",
  //   PICK_FROM_SET = "PICK_FROM_SET",
  //   PICK_ANY = "PICK_ANY",
}
