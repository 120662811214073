












import SettingsChat from "@/components/General/SettingsChat.vue";
import SettingsTabs from "@/components/General/SettingsTabs.vue";
import PageTemplate from "@/components/PageTemplate.vue";
import BroadcastSelect from "@/components/Broadcast/BroadcastSelect.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";
import { Broadcast } from "@/components/Broadcast/broadcast/types";

@Component({
  components: {
    PageTemplate,
    SettingsTabs,
    SettingsChat,
    BroadcastSelect,
  },
})
export default class GeneralSettings extends Vue {
  RouteName = RouteName;

  selectedBroadcast: Broadcast | null = null;
}
