

















import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { BroadcastGetters, Broadcast } from "./broadcast/types";
import { CoreUserGetters, User } from "@/spect8-core-vue/src/types";

@Component
export default class BroadcastSelect extends Vue {
  @Prop({ default: null }) readonly value!: Broadcast | null;
  @Prop({ default: false }) readonly disabled!: boolean;

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];
  @Getter(CoreUserGetters.User)
  readonly user!: User | null;

  get selectedBroadcast(): Broadcast | null {
    return this.value;
  }
  set selectedBroadcast(val: Broadcast | null) {
    this.$emit("input", val);
  }

  created() {
    // user is broadcast moderator
    if (
      this.user &&
      this.user.isOnlyBroadcastModerator() &&
      this.broadcasts.length > 0
    ) {
      const broadcast = this.broadcasts[0];
      this.selectedBroadcast = broadcast;
    }
  }
}
