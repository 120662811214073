








































import { Action, Getter } from "vuex-class";
import { Component, Vue, Ref, Watch, Prop } from "vue-property-decorator";
import {
  Navigation,
  NavigationDeleteRequest,
  NavigationId,
  NavigationRequest,
} from "@/store/generalSettings/types";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import TabSwitch from "./TabSwitch.vue";
import { ValidationObserver } from "vee-validate";
import { VForm } from "@/types";
import { apiChat } from "@/services/api.service";
import { showNotification } from "@/helpers";

@Component({
  components: { TabSwitch, ValidationObserver },
})
export default class SettingsTabs extends Vue {
  @Prop({ required: true }) readonly broadcast!: Broadcast | null;

  @Ref("observer") observer!: InstanceType<typeof ValidationObserver>;
  @Ref("form") form!: VForm;

  @Action("generalSettings/getNavbarItems")
  getNavbarItems!: (broadcastId: string | null) => Promise<void>;
  @Action("generalSettings/addNavbarItem")
  addNavbarItem!: (data: NavigationRequest) => Promise<void>;
  @Action("generalSettings/createNavbarItem")
  createNavbarItem!: (data: NavigationRequest) => Promise<void>;
  @Action("generalSettings/deleteNavbarItem")
  deleteNavbarItem!: (data: NavigationDeleteRequest) => Promise<void>;

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];
  @Getter("generalSettings/navbarItems")
  readonly navbarItems!: Navigation;

  NavigationId = NavigationId;

  tabsAvailable = [
    NavigationId.CHAT,
    NavigationId.MERCH,
    NavigationId.POLLS,
    NavigationId.QA,
    NavigationId.RESOURCES,
  ];

  tabs = {
    [NavigationId.CHAT]: {
      title: `tabManagement.chat`,
      icon: "mdi-message-text",
    },
    [NavigationId.MERCH]: {
      title: `tabManagement.shop`,
      icon: "mdi-cart",
    },
    [NavigationId.POLLS]: {
      title: `tabManagement.polls`,
      icon: "mdi-ballot",
    },
    [NavigationId.QA]: {
      title: `tabManagement.qa`,
      icon: "mdi-frequently-asked-questions",
    },
    [NavigationId.RESOURCES]: {
      title: `tabManagement.resources`,
      icon: "mdi-attachment",
    },
  };

  tabsActive: Record<NavigationId, boolean> = {
    [NavigationId.CHAT]: false,
    [NavigationId.MERCH]: false,
    [NavigationId.POLLS]: false,
    [NavigationId.QA]: false,
    [NavigationId.RESOURCES]: false,
  };

  get tabsActiveNum(): number {
    const res = Object.values(this.tabsActive).filter((bool) => bool).length;
    return res;
  }

  get noTabsActive(): boolean {
    return this.tabsActiveNum <= 0;
  }

  loading = false;
  sending = false;

  @Watch("broadcast", { immediate: true })
  onBroadcastSelect(broadcast: Broadcast | null) {
    this.loading = true;
    apiChat
      .getTabs(broadcast?.id)
      .then((res) => {
        this.clear();
        res.tabs.forEach((tab) => {
          this.tabsActive[tab.name] = true;
        });

        return this.$nextTick();
      })
      .then(() => {
        this.observer.reset();
        this.loading = false;
      });
  }

  clear() {
    Object.keys(this.tabsActive).forEach((t) => {
      this.tabsActive[t as NavigationId] = false;
    });
  }

  toggleTab(tab: NavigationId, active: boolean) {
    this.tabsActive[tab] = active;
  }

  submit() {
    if (!this.form || !this.form.validate()) return;

    this.sending = true;

    const tabs = Object.entries(this.tabsActive).flatMap(([id, active]) =>
      active ? [id as NavigationId] : []
    );

    apiChat
      .setTabs({
        name: tabs,
        broadcastId: this.broadcast ? this.broadcast.id : null,
      })
      .then((res) => {
        if (res && res.tabs && res.tabs.length === tabs.length)
          showNotification(200, "tabManagement.notifications.success");
      })
      .catch((err) => {
        if (err.status && err.message)
          showNotification(err.status, err.message);
        console.error(err);
      })
      .finally(() => {
        this.sending = false;
        this.observer.reset();
      });
  }
}
